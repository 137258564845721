import React from 'react';
import instagram from './images/instagram.png';
import whatsapp from './images/whatsapp.png';
import telegram from './images/telegram.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-company">
        Runtime
      </div>
      <div>
        <div className="App-work">
          Ведутся работы по сайту
        </div>
        <div className="App-message">
          <p>Новая версия сайта будет запущена в скором времени.</p>
          <p>Отслеживать новости Runtime можно в социальных сетях.</p>
        </div>
      </div>
      <div className="App-social">
        <a
          href="https://www.instagram.com/runtime.kz"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="Instagram logo"/>
        </a>
        <a
          href="https://wa.me/77024111198"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="WhatsApp logo"/>
        </a>
        <a
          href="https://t.me/Runtimekz"
          target="_blank"
          rel="noreferrer"
        >
          <img src={telegram} alt="Telegram logo"/>
        </a>
      </div>
    </div>
  );
}

export default App;
